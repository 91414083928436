import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "./AuthContext";
import { useSocket } from "./SocketContext";

const useGroupSocket = ({
  setGroupUnreadCount,
  groupChatListData,
  setGroupChatListData,
}) => {
  const { socket } = useSocket();
  const { user: loggedInUser } = useAuth();
  const queryClient = useQueryClient();



  useEffect(() => {
    if (!socket) return;

    const handleReadByUsers = ({ readByUsers, groupId }) => {
      if (readByUsers?.includes(loggedInUser?.userId)) {
        setGroupUnreadCount((prevState) => {
          const prevUnreadCount = groupChatListData?.find(
            (group) => group?.id === groupId
          )?.unreadCount || 0;
          const newTotalCount =
            prevState.group < prevUnreadCount ? 0 : prevState.group - prevUnreadCount;
          return {
            message: prevState.message,
            group: newTotalCount,
          };
        });

        setGroupChatListData((prevState) =>
          prevState.map((group) =>
            group?.id === groupId
              ? { ...group, unreadCount: 0 }
              : group
          )
        );
      }
    };

    const handleNewGroupMessage = async(data) => {
      const groupIndex = groupChatListData.findIndex(
        (group) => group?.id === data.messages[0].groupId
      );
      if (groupIndex === -1) {
        return await queryClient?.invalidateQueries({ queryKey: ["group","list","chats"] });;
      }
      setGroupChatListData((prevState) => {
        const groupIndex = prevState.findIndex(
          (group) => group?.id === data.messages[0].groupId
        );
        if (groupIndex === -1) return prevState;

        const oldGroup = prevState[groupIndex];
        const newGroup = {
          ...oldGroup,
          lastMessage: data?.messages[0]?.content,
          lastMessageTimeStamp: data?.messages[0]?.timestamp,
          unreadCount:
            data?.messages[0]?.senderId === loggedInUser?.userId
              ? oldGroup?.unreadCount
              : oldGroup?.unreadCount + 1,
        };

        const newGroups = [...prevState];
        newGroups.splice(groupIndex, 1);
        newGroups.unshift(newGroup);

        return newGroups;
      });
    };

    const handleReValidate = async (data) => {
      await queryClient?.invalidateQueries([
        { queryKey: ["group","list","chats"] },
      ]);
    };

    socket.on("readByUsers", handleReadByUsers);
    socket.on("gettingNewGroupMessage", handleNewGroupMessage);
    socket.on("newGroupMessage", handleReValidate);
    socket.on("newGroupCreated", handleReValidate);

    return () => {
      socket.off("readByUsers", handleReadByUsers);
      socket.off("gettingNewGroupMessage", handleNewGroupMessage);
      socket.off("newGroupMessage", handleReValidate);
      socket.off("newGroupCreated", handleReValidate);
    };
  }, [socket, groupChatListData, loggedInUser, queryClient, setGroupChatListData, setGroupUnreadCount]);
};

export default useGroupSocket;

