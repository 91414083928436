import React, { Fragment } from "react";

import { Typography } from "@material-tailwind/react";
import moment from "moment";
import DocImage from "../../../Assets/Images/doc.png";
import PDFImage from "../../../Assets/Images/pdf.png";
import URLImage from "../../../Assets/Images/url.png";
import VIDImage from "../../../Assets/Images/video.png";
import ImageOrInitials from "../../../Components/Functionality/ImageOrInitials";
import { notifyCssSidebar } from "../../../Utils/Constant";

const GroupSidebarCard = ({ item, pathname }) => {
  const renderContent = () => {
    switch (item?.lastMessage?.type) {
      case "text":
        return (
          <span className="flex items-center gap-2">
            {item?.lastMessage?.content?.text}
          </span>
        );

      case "image":
        return <span className="flex items-center gap-2">Photo</span>;

      case "doc":
        return (
          <span className="flex items-center gap-2">
            {" "}
            <img
              src={DocImage}
              className="inline max-w-[15px] max-h-[15px]"
              alt="doc-img"
            />{" "}
            Document
          </span>
        );

      case "pdf":
        return (
          <span className="flex items-center gap-2">
            {" "}
            <img
              src={PDFImage}
              className="inline max-w-[15px] max-h-[15px]"
              alt="pdf-img"
            />{" "}
            PDF
          </span>
        );

      case "video":
        return (
          <span className="flex items-center gap-2">
            {" "}
            <img
              src={VIDImage}
              className="inline max-w-[15px] max-h-[15px]"
              alt="video-img"
            />{" "}
            Video
          </span>
        );

      case "url":
        return (
          <span className="flex items-center gap-2">
            {" "}
            <img
              src={URLImage}
              className="inline max-w-[15px] max-h-[15px]"
              alt="url-img"
            />{" "}
            Link
          </span>
        );
      case "profile":
        return <span className="flex items-center gap-2">Profile shared</span>;
      case "motion":
        return <span className="flex items-center gap-2">Motion shared</span>;
      case "debate":
        return <span className="flex items-center gap-2">debate shared</span>;
      case "user_added":
        return (
          <span className={notifyCssSidebar}>
            {item?.lastMessage?.content?.userName} has been added by
          </span>
        );
      case "user_removed":
        return (
          <span className={notifyCssSidebar}>
            {item?.lastMessage?.content?.userName} has been removed by
          </span>
        );
      case "user_left":
        return (
          <span className={notifyCssSidebar}>
            {item?.lastMessage?.content?.userName} has left the group
          </span>
        );
      case "group_updated":
        return (
          <span className={notifyCssSidebar}>Group has been updated by </span>
        );
      default:
        return (
          <span className="flex items-center gap-2">
            Unsupported message type
          </span>
        );
    }
  };

  return (
    <Fragment>
      <ImageOrInitials
        imageSrc={item?.groupAvatar}
        initials={item?.name}
        classNameInitials={"text-xs"}
        size={"h-10 w-10"}
        showUserStatus={false}
      />
      <div className="flex flex-1 flex-col justify-between">
        <div className="flex justify-between items-center">
          <Typography
            color="white"
            className={
              pathname === `/group-message/${item?.id}`
                ? `bg-primary text-sm font-semibold truncate max-w-[120px]`
                : ` truncate text-sm max-w-[120px]`
            }
          >
            {item?.name}
          </Typography>{" "}
          <Typography
            color="white"
            variant="small"
            className="text-[11px] font-semibold normal-case shrink-0"
          >
            {(() => {
              const timeAgo = moment(item.lastMessageTimeStamp).fromNow(true);
              if (timeAgo.includes("minutes")) {
                return timeAgo.replace(" minutes", "m");
              } else if (timeAgo.includes("hours")) {
                return timeAgo.replace(" hours", "h");
              } else if (timeAgo.includes("days")) {
                return timeAgo.replace(" days", "d");
              }
              return timeAgo;
            })()}
          </Typography>{" "}
        </div>
        <div className="flex items-center justify-between">
          <Typography
            color="white"
            variant="small"
            className={
              pathname === `/group-message/${item?.id}`
                ? `bg-primary text-xs max-w-[130px] truncate`
                : `max-w-[130px] text-xs truncate`
            }
            style={{ width: "100%" }}
          >
            {renderContent()}
          </Typography>

          {item?.unreadCount === 0 || !item?.unreadCount ? null : (
            <div className="h-4 w-4 rounded-full flex items-center justify-center shrink-0 bg-red-500 text-debatePalette-background text-xs">
              {item?.unreadCount > 9 ? "9+" : item?.unreadCount}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default GroupSidebarCard;
